<template>
  <div v-if="wallet !== null">
    <b-card>

      <b-form>
        <b-row>

          <b-col
              cols="12"
              class="mb-1"
          >
            <h3>افزایش موجودی کیف پول</h3>
          </b-col>

          <!-- Field: credit -->
          <b-col
              cols="12"
              md="4"
          >
            <b-form-group
                label-for="credit"
                label="مقدار موجودی فعلی (تومان)"
            >
              <b-form-input
                  id="credit"
                  disabled
                  v-model="wallet.credit"
              />
            </b-form-group>
          </b-col>

          <b-button
              variant="success"
              class="mt-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="toggleInputs('i')"
          >
            افزایش موجودی
          </b-button>

          <b-button
              variant="danger"
              class="mt-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="toggleInputs('d')"
          >
            کاهش موجودی
          </b-button>

        </b-row>
        <b-row  v-if="showIncrease">
          <!-- Field: increaseCredit -->
          <b-col
              cols="12"
              md="4"
              class="mt-2"
          >
            <b-form-group
                label-for="increaseCredit"
                label="افزایش موجودی"
            >
              <b-form-input
                  id="increaseCredit"
                  v-model="newWalletData.credit"
              />
            </b-form-group>
            <!-- Action Buttons -->
            <b-button
                variant="primary"
                class="mt-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="updateWallet"
            >
              ذخیره تغییرات
            </b-button>
          </b-col>
        </b-row>
        <b-row  v-if="showDecrease">
          <!-- Field: decreaseCredit -->
          <b-col
              cols="12"
              md="4"
              class="mt-2"
          >
            <b-form-group
                label-for="decreaseCredit"
                label="کاهش موجودی"
            >
              <b-form-input
                  id="decreaseCredit"
                  v-model="newWalletData.credit"
              />
            </b-form-group>
            <!-- Action Buttons -->
            <b-button
                variant="primary"
                class="mt-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="updateWallet"
            >
              ذخیره تغییرات
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BFormGroup, BForm,BModal,
} from 'bootstrap-vue'
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {WalletGetByUserIdRequest} from "@/libs/Api/Wallet";
import {WalletUpdateRequest} from "@/libs/Api/Wallet";


export default {
  name: "WalletInfo",
  title:"ویرایش کیف پول - پنل ادمین مکس ",
  data(){
    return{
      wallet:null,
      userId:this.$route.params.id,
      showIncrease:false,
      showDecrease:false,
      newWalletData:{
        userId:this.$route.params.id,
        credit:'',
      }
    }
  },
  async created(){
    await this.getWallet();
  },
  methods:{
    async getWallet(){
      let _this = this;

      let walletGetByUserIdRequest = new WalletGetByUserIdRequest(_this);
      walletGetByUserIdRequest.setUserId(_this.userId);
      await walletGetByUserIdRequest.fetch(function (content){
        _this.wallet = content[0]
      },function (error){
        console.log(error)
      })
    },
    async updateWallet(){
      let _this = this;

      if (_this.showDecrease){
        _this.newWalletData.credit = '-'+_this.newWalletData.credit;
      }

      let walletUpdateRequest = new WalletUpdateRequest(_this);
      walletUpdateRequest.setParams(_this.newWalletData);
      await walletUpdateRequest.fetch(function (content){
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `تغییرات اعمال شد.`,
          },
        })
        _this.getWallet();
      },function (error){
        console.log(error)
      })
    },
    toggleInputs(param){
      let _this = this;

      if (param === 'i'){
        _this.showIncrease = true;
        _this.showDecrease = false;
      }else {
        _this.showIncrease = false;
        _this.showDecrease = true;
      }
    },
  },
  components:{
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BFormGroup,
    BBadge,
    BForm,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,

    vSelect,
  }
}
</script>

<style scoped>

</style>